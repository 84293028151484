.multi-table {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: left;
    padding: 16px;
    &:has(.modd-button-group) { padding: 8px 0; }
  }
  td {
    border-top: 1px solid rgba(128, 128, 128, 0.5);
    background: #fff;
    padding: 8px 16px;
  }

  &.loading { opacity: 0.5; }
  &.condensed td { padding: 8px 4px;  }
  &.clickable tr { cursor: pointer; }
  &.clickable tr:hover td { background-color: #eef; }

  &.draggable {
    &.dragging {
      tr:not(.held) { transition: 0.2s; }
      .held {
        z-index: 100;
        position: relative;
      }
    }
    tbody td:first-child {
      width: 2rem;
      .modd-icon { margin: 0 auto; }
      padding: 0;
      cursor: move;
    }
  }
}
