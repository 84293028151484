.dnd-grid {
  display: block;
  width: 100%;
  position: relative;
}
.dnd-grid > * {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transition: 1s;
}
.dnd-grid.draggable > * {
  cursor: move;
}
.dnd-grid .dragging {
  transform: scale(1.1);
  z-index: 100;
  transition: 0s;
}
.dnd-grid.snapping > * {
  transition: 0s;
}
.dnd-grid.loading > * {
  opacity: 0.5;
}

.dnd-grid-item > * {
  height: 100%;
}
