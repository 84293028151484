body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-map {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
}
.icon-map > * {
  margin: 24px;
  text-align: center;
}

.hierarchy td:first-child > * { position: relative; }
.hierarchy tr td:first-child {
  width: 200px;
}

.loading-faded { opacity: 0.5; }

.activity-banner {
  display: block;
  position: relative;
  min-width: 48px;
  min-height: 32px;
}
.activity-banner > * { position: absolute;  }
.activity-banner > *:nth-child(1) { right: 0; }
.activity-banner > *:nth-child(2) { right: 12px; }
.activity-banner > *:nth-child(3) { right: 24px; }
.activity-banner > *:nth-child(4) { right: 36px; }
.activity-banner > *:nth-child(5) { right: 48px; }
.activity-banner > *:nth-child(6) { right: 60px; }
.activity-banner > *:hover { z-index: 10; }

.collection-handle { font-size: 0.95em; color: #aaa; }

.activity-badge {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaa;
  cursor: pointer;
}

.activity-badge.read { border-color: #9d9; }
.activity-badge.write { border-color: #d99; }

.flat img, .flat svg, .hierarchy img, .hierarchy svg { max-width: 48px; }
.hierarchy .multi-table table td { border-top: 1px solid #ddd; }
.hierarchy .depth0 td:first-child > * { left: 0px; }
.hierarchy .depth1 td:first-child > * { left: 52px; }
.hierarchy .depth1 { background: linear-gradient(to right, #ddd, #ddd 52px, #fff 52px) }
.hierarchy .depth2 td:first-child > * { left: 104px; }
.hierarchy .depth2 { background: linear-gradient(to right, #ddd, #ddd 104px, #fff 104px) }
.hierarchy .depth3 td:first-child > * { left: 156px; }
.hierarchy .depth3 { background: linear-gradient(to right, #ddd, #ddd 156px, #fff 156px) }
.hierarchy .depth4 td:first-child > * { left: 208px; }
.hierarchy .depth4 { background: linear-gradient(to right, #ddd, #ddd 208px, #fff 208px) }
.hierarchy .depth5 td:first-child > * { left: 260px; }
.hierarchy .depth5 { background: linear-gradient(to right, #ddd, #ddd 260px, #fff 260px) }
.hierarchy .depth6 td:first-child > * { left: 312px; }
.hierarchy .depth6 { background: linear-gradient(to right, #ddd, #ddd 312px, #fff 312px) }

.table {
  width: 100%;
}
.table td, .table th { padding: 12px; }

.graph {
  overflow-y: auto;
  scrollbar-width: thin;
}
.graph::-webkit-scrollbar { width: 4px; }
.graph::-webkit-scrollbar-track { background: #f1f1f1; }
.graph::-webkit-scrollbar-thumb { background: #888; }
.graph::-webkit-scrollbar-thumb:hover { background: #555; }

.counter-graph {
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  line-height: 170px;
}

.conditions {
  background: #fff;
  .conditions {
    background: #eee;
    .conditions {
      background: #ddd;
      .conditions {
        background: #ccc;
      }
    }
  }
  list-style: none;
  padding-left: 0;
  display: block;
  width: 100%;
  & > li {
    margin: 0;
    display: block;
    padding: 6px 0;
    border-left: 0;
  }
  .conditions {
    padding-left: 22px;
  }
  .no-conditions {
    color: #888;
    padding: 12px 12px 12px 0;
  }
  .draghandle {
    cursor: move;
  }
  .dragging {
    opacity: 0.5;
    transition: 0s;
  }
  &.dropping {
    border-bottom: 4px solid green;
  }
  .dropping {
    border-bottom: 4px solid green;
  }
}

/* Search Preview */
.search-preview {
  position: relative;
}
.search-preview-badge {
  position: absolute;
  top: 0;
  left: 0;
  background: #ccc;
  color: #fff;
  padding: 10px 6px;
}

.spot-preview-banner {
  aspect-ratio: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  flex-direction: column;
  background-size: cover;
  position: relative;
  max-height: 100%;
}
.spot-preview-banner-title { font-size: 24px; }
.spot-preview-banner-subtitle { font-size: 16px; margin-top: 12px; color: #aaa; }
.spot-preview-banner button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(255,255,255,0.25);
  padding: 0.7rem 1.0rem;
  border: 0;
}
.dnd-grid-item .spot-preview-banner {
  margin-left: 10%;
  width: 80%;
  max-height: 80%;
} 

.swatch-preview-card { position: relative; }
.swatch-preview-card-delete { position: absolute; top: 0; right: 0; z-index: 10; }
.swatch-preview-small { display: inline-block; width: 24px; height: 24px; margin-top: 6px; background-repeat: no-repeat; background-size: contain; }
.swatch-preview-large { display: inline-block; width: 128px; height: 128px; background-repeat: no-repeat; background-size: contain; }
.search-preview-badge-position-left { top: 50%; left: 0; }
.search-preview-badge-position-right { top: 50%; left: auto; right: 0; }
.search-preview-badge-position-top-left { top: 0; left: 0; }
.search-preview-badge-position-top-right { top: 0; left: auto; right: 0; }
.search-preview-badge-position-bottom-right { bottom: 0; top: auto; left: auto; right: 0; }
.search-preview-badge-position-bottom-left { bottom: 0; top: auto; left: 0; }
.search-preview-badge-position-bottom { bottom: 0; left: 50%; }

.search-preview-merchandising-actions {
  position: absolute;
  right: 4px;
  bottom: 4px;
  display: flex;
  gap: 4px;
}

.collection-details .search-preview-badge { left: 50px; }
.collection-details .search-preview-badge-position-left { left: 50px; }
.collection-details .search-preview-badge-position-top-left { left: 50px; }
.collection-details .search-preview-badge-position-bottom-left { left: 50px; }
.collection-details .search-preview-badge-position-right { left: auto; right: 50px; }
.collection-details .search-preview-badge-position-bottom-right { left: auto; right: 50px; }
.collection-details .search-preview-badge-position-top-right { left: auto; right: 50px; }


.search-preview-image {
  display: block;
  text-align: center;
}
.search-preview-image img {
  max-width: 100%;
  max-height: 256px;
}

.search-preview-title {
  text-align: center;
  display: inline-block;
  max-width: 50%;
  margin: 0 auto;
  text-decoration: none;
}

.search-preview-product, .search-preview-banner {
  text-decoration: none;
  position: relative;
  color: #777;
  font-size: 16px;
  padding: 36px 12px;
  box-sizing: border-box;
  text-align: center;
  border-right: 1px solid #eee;
}

.search-preview-product .modd-popover-children > * {
  max-height: 300px;
}

.search-preview-product .modd-thumbnail.modd-loading {
  opacity: 0.5;
}

.dropzone .search-preview-product, .dropzone .search-preview-banner {
  cursor: grabbing;
}

.search-preview-delete, .search-preview-setimage, .search-preview-pinned, .search-preview-antipinned, .search-preview-select, .search-preview-info, .search-preview-pinned-ordinal {
  position: absolute;
  top: 8px;
  padding: 8px;
  cursor: pointer;
}
.search-preview-select { left: 8px; background: none; }
.search-preview-delete { right: 8px; }
.search-preview-setimage { right: -2px; top: 46px; }
.search-preview-pinned-ordinal { top: 48px; border: 1px solid #aaa; border-radius: 24px; width: 36px; height: 36px; text-align: center; padding: 0; }
.search-preview-pinned { left: 12px; top: 90px; }
.search-preview-antipinned { left: 12px; top: 52px; }
.search-preview-info {
  font-size: 12px;
  text-align: left;
  background: rgba(255,255,255,0.8);
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  top: 84px;
  right: 0;
  padding: 8px;
}

.dropzone {
  flex: 1;
  height: 300px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}


.search-preview-row {
  display: flex;
}

.search-preview-price {
  text-align: center;
  display: block;
  margin-top: 12px;
  font-size: 24px;
}

.search-preview-panel .facet-value {
  cursor: pointer;
  margin: 12px 0;
}

.search-preview-sidebar {
  border-right: 1px solid #eee;
  position: sticky;
  box-sizing: border-box;
  top: 52px;
  width: 250px;
  height: 100vh;
  padding: 12px;
  overflow-y: auto;
}
.no-results .search-preview-sidebar { display: none !important; }
.search-preview-sidebar::-webkit-scrollbar {  width: 6px; }
.search-preview-sidebar::-webkit-scrollbar-track { background: #f1f1f1; }
.search-preview-sidebar::-webkit-scrollbar-thumb {background: #888; }
.search-preview-sidebar::-webkit-scrollbar-thumb:hover { background: #07f; }

.search-preview-panel .facet {
  padding-right: 8px;
}

.search-preview-sidebar .facet-title {
  display: block;
  font-size: 20px;
  font-variant: small-caps;
  padding-bottom: 4px;
  margin-bottom: 4px;
  margin-top: 24px;
  border-bottom: 1px solid #eee;
}

.search-preview-sidebar .facet-value.inactive {
  display: none !important;
}

.search-preview-sidebar .facet-value .count {
  float: right;
  color: #ccc;
}

.search-preview-sidebar .breadcrumbs > * {
  background: #07f;
  border: 0;
  color: #fff;
  margin: 4px;
  cursor: pointer;
  padding: 2px 6px;
}

.search-preview-sidebar .facet drange {
  width: 100%;
}

.search-preview-sidebar .facet drange handle {
  /* background: #000; */
  border-radius: 0;
  width: 5px;
  height: 18px;
  padding: initial;
  transform: translate(7px, 0);
}

.search-preview-sidebar .facet drange bar.fill {
  /* background: #000; */
}

.search-preview-sidebar .facet input[type='checkbox'], .search-preview-sidebar .facet input[type='radio'] {
  /* accent-color: #000; */
  width: 16px;
  height: 16px;
}

.search-preview-sidebar .facet-value > * {
  vertical-align: text-bottom;
}

.swatch-picker input {
  min-height: 160px;
}

.swatch-grid {
  .modd-card + .modd-card {
    margin-top: 0;
  }
}

/***** Swatch Extension *********/
.facet-pane.swatches .breadcrumb.breadcrumb-f_color:before,  .facet-pane.swatches .facet-f_color .facet-value:before,  .facet-pane.swatches .facet-f_colour .facet-value:before {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  content: '';
  margin-right: 4px;
  margin-top: -2px;
  vertical-align: middle;
  display: inline-block;
  background-size: contain;
}
.facet-pane.swatches .breadcrumb-f_color_red:before, .facet-pane.swatches .facet-value-f_color_red:before { background: #f00; }
.facet-pane.swatches .breadcrumb-f_color_blue:before, .facet-pane.swatches .facet-value-f_color_blue:before { background: #00f; }
.facet-pane.swatches .breadcrumb-f_color_yellow:before, .facet-pane.swatches .facet-value-f_color_yellow:before { background: #ff0; }
.facet-pane.swatches .breadcrumb-f_color_beige:before, .facet-pane.swatches .facet-value-f_color_beige:before { background: #fc8; }
.facet-pane.swatches .breadcrumb-f_color_green:before, .facet-pane.swatches .facet-value-f_color_green:before { background: #0a0; }
.facet-pane.swatches .breadcrumb-f_color_brown:before, .facet-pane.swatches .facet-value-f_color_brown:before { background: #420; }
.facet-pane.swatches .breadcrumb-f_color_black:before, .facet-pane.swatches .facet-value-f_color_black:before { background: #000; }
.facet-pane.swatches .breadcrumb-f_color_white:before, .facet-pane.swatches .facet-value-f_color_white:before { background: #fff; }
.facet-pane.swatches .breadcrumb-f_color_grey:before, .facet-pane.swatches .facet-value-f_color_grey:before { background: #777; }
.facet-pane.swatches .breadcrumb-f_color_gray:before, .facet-pane.swatches .facet-value-f_color_gray:before { background: #777; }
.facet-pane.swatches .breadcrumb-f_color_orange:before, .facet-pane.swatches .facet-value-f_color_orange:before { background: #f70; }
.facet-pane.swatches .breadcrumb-f_color_pink:before, .facet-pane.swatches .facet-value-f_color_pink:before { background: #f0f; }
.facet-pane.swatches .breadcrumb-f_color_purple:before, .facet-pane.swatches .facet-value-f_color_purple:before { background: #a07; }
.facet-pane.swatches .breadcrumb-f_color_tan:before, .facet-pane.swatches .facet-value-f_color_tan:before { background: #fdb; }

/****** Bolding Pane Extension *******/
.facet-pane.styling-bolding .facet-value input {
  display: none;
}
.facet-pane.styling-bolding .facet-value.active {
  font-weight: bold;
}

div.code-input {
  position: relative;
  padding: 1rem;
  height: 200px;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(201, 204, 207, 1);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  &:focus-visible {
    outline: 1px solid #48f
  }
  &.modd-error {
    border: 1px solid rgba(255, 107, 107, 1);
  }
}

.code-input-read-only {
  display: none;
  position: absolute;
  z-index: 1;

  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.code-input-read-only.active {
  display: block;
}



/* Modification of the prism-funky.min.css theme */
.code-input pre[class*="language-"],
.code-input code[class*=language-] {
  background: transparent;
  box-shadow: none;
}

textarea.editing, pre.highlighting {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;

  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  /* In the same place */
  position: absolute;
  left: 1rem;
  top: 1rem;

  /* Can be scrolled */
  overflow: auto;
  white-space: pre;
}

textarea.editing, pre.highlighting, pre.highlighting * {
  /* Also add text styles to highlighing tokens */
  font-size: 1rem;
  font-family: "Fira Code", monospace;
  line-height: 1.5rem;
  tab-size: 2;
}

textarea.editing {
  /* Make textarea almost completely transparent */
  color: transparent;
  background-color: transparent;

  caret-color: #222; /* Or choose your favorite color */
  resize: none; /* No resize on textarea */
}


.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #aaa;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
  color: #0cf;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin {
  color: #060;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.token.variable,
.token.inserted {
  color: #009;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: deeppink;
}

.token.regex,
.token.important {
  color: orange;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.deleted {
  color: red;
}

/* Plugin styles: Diff Highlight */
pre.diff-highlight.diff-highlight > code .token.deleted:not(.prefix),
pre > code.diff-highlight.diff-highlight .token.deleted:not(.prefix) {
  background-color: rgba(255, 0, 0, .3);
  display: inline;
}

pre.diff-highlight.diff-highlight > code .token.inserted:not(.prefix),
pre > code.diff-highlight.diff-highlight .token.inserted:not(.prefix) {
  background-color: rgba(0, 255, 128, .3);
  display: inline;
}

.modd-button.modd-action-list {
  min-height: 36px;
}
