
/* 
z-indexe:
modd-text-field, etc..: 20
modd-top-nav: 65
modd-contextual-save-bar: 70
modd-popover-children > *: 80
modd-tooltip-content: 90
modd-overlay: 100
modd-modal: 101
modd-toast: 1000
*/

html, body { 
  color: rgb(32, 34, 35);
  font-size: 14px;
  line-height: 20px;
}

.modd-heading {
  font-size: 1.2em;
  margin: 4px 0;
}

.modd-stack { display: flex; gap: 12px 4px; }
.modd-stack.vertical { flex-direction: column; }
.modd-stack.reverse { flex-direction: row-reverse; }
.modd-stack.vertical.reverse { flex-direction: column-reverse; }
.modd-stack.distribution-flexStart { justify-content: flex-start; }
.modd-stack.distribution-flexEnd { justify-content: flex-end; }
.modd-stack.distribution-start { justify-content: start; }
.modd-stack.distribution-end { justify-content: end; }
.modd-stack.distribution-left { justify-content: left; }
.modd-stack.distribution-right { justify-content: right; }
.modd-stack.distribution-center { justify-content: center; }
.modd-stack.distribution-spaceBetween { justify-content: space-between; }
.modd-stack.distribution-spaceAround { justify-content: space-around; }
.modd-stack.distribution-spaceEvenly { justify-content: space-evenly; }
.modd-stack.spacing-none { gap: 0; }
.modd-stack.spacing-extraTight { gap: 3px 1px; }
.modd-stack.spacing-tight { gap: 6px 2px; }
.modd-stack.spacing-normal { gap: 12px 4px; }
.modd-stack.spacing-loose { gap: 18px 6px; }
.modd-stack.spacing-extraLoose { gap: 24px 8px; }
.modd-stack > .modd-stack-item { flex: 0 1 auto; }
.modd-stack > .modd-stack-item:empty { display: none; }
.modd-stack.distribution-fillEvenly > .modd-stack-item { flex: 1 0; min-width: fit-content; }
.modd-stack.distribution-fill > .modd-stack-item { flex: 1 1 auto; }
.modd-stack-item.modd-fill { flex-grow: 1; }
.modd-stack-item.modd-shrink { flex-shrink: 1; }
.modd-stack.alignment-stretch { align-items: stretch; }
.modd-stack.alignment-flexStart { align-items: flex-start; }
.modd-stack.alignment-flexEnd { align-items: flex-end; }
.modd-stack.alignment-center { align-items: center; }
.modd-stack.alignment-baseline { align-items: baseline; }
.modd-stack.wrap { flex-wrap: wrap; }

.modd-link, .modd-link:visited { text-decoration: none; color: #37C; }
.modd-link:hover { text-decoration: none; color: #15A; }

ui-nav-menu { display: none !important; }
.modd-full-screen { min-height: 100vh; }

@media screen and (min-width: 520px) {
  .modd-display-mobile { display: none; }
}
.modd-top-nav {
  padding: 12px;
  background: #eee;
  position: sticky;
  top: 0;
  z-index: 65;
  .modd-top-navigation-button {
    padding: 5px 12px;
    border-radius: 8px;
    background: #fff;
  }
}

svg { fill: #999; }
.modd-button {
  cursor: pointer;
  position: relative;
  border: 1px solid #ccc;
  background: #fff;
  color: #111;
  .modd-spinner svg { fill: #111; }
  font-size: 14px;
  &:hover:not(:disabled) { background-color: #eee; }
  &:active:not(:disabled), &.modd-pressed { background-color: #ddd; }
  border-radius: 4px;
  padding: 9px 12px;
  font-weight: var(--p-button-font-weight, 400);
  line-height: 16px;
  &.modd-primary { 
    background-color: #086; 
    color: #fff; 
    svg { fill: #fff; }
    border: 1px solid #086;
    &:hover:not(.modd-plain) { background-color: #075; }
    &:active, &.modd-pressed { background-color: #065; }
    &:disabled, &:hover:disabled { background-color: #9ba; border: 1px solid #9ba; color: #fff; svg { fill: #fff; } }
    &.modd-plain { color: #086; svg { fill: #086; } background: none; border: 1px solid transparent; &:hover { background: #eee; border: 1px solid #eee; } }
    &.modd-plain:disabled { color: #9ba; svg { fill: #9ba !important; } }
  }
  &.modd-destructive { 
    background-color: #f00; 
    color: #fff; 
    border: 1px solid #f00;
    &:hover { background-color: #e00; }
    &:active, &.modd-pressed { background-color: #c00; }
    svg { fill: #fff; }
  }
  &:disabled { cursor: not-allowed; }
  &:disabled:not(.modd-primary):not(.modd-plain) { background: #eee; color: #999; svg { fill: #999 !important; } }
  &.modd-plain { 
    background: none; border: 0;
    &:disabled { color: #999; svg { fill: #999 !important; } }
  }
  &.modd-square { padding: 7px; }
  &.modd-slim.modd-square { padding: 4px; }
  &.modd-slim { padding: 0px; }
}
.modd-full-width { width: 100% !important; }

.modd-banner {
  border-radius: 8px;
  padding: 20px 20px 18px;
  background: #eee;
  border: 1px solid #999;
  .modd-banner-title {
    font-size: 16px;
    font-weight: 600;
  }
  .modd-button { background: none; }
  &.modd-warning { background: #fed; border: 1px solid #c90; }
  &.modd-success { background: #efd; border: 1px solid #470; }
  &.modd-danger, &.modd-critical { background: #fee; border: 1px solid #b00; }
}

.modd-color-warning > svg { fill: #b90; }
.modd-color-danger > svg { fill: #b00; }
.modd-color-critical > svg { fill: #b00; }
.modd-color-success > svg { fill: #086; }
.modd-color-interactive > svg { fill: #08F; }

.modd-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  outline: 1px solid transparent;

  .modd-card-header {
    padding: 20px 20px 0;
    .modd-card-header-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .modd-card-footer {
    padding: 0 20px 20px 20px;
  }

  .modd-card-section {
    padding: 20px;
    &+ .modd-card-section:not(.modd-no-border) {
      border-top: 1px solid #eee;
    } 
    .modd-card-section-title {
      font-weight: 600;
      line-height: 16px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 0;
    }
    .modd-card-section-header:not(:only-child) {
      margin-bottom: 12px;
    }
    &:empty { display: none; }
  }

  &+ .modd-card {
    margin-top: 16px;
  }
}

.modd-page {
  margin: 18px auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
  max-width: 998px;
  
  &.modd-full-width {
    max-width: 100%;
    margin: 18px 0;
  }
  .modd-page-title {
    font-size: 20px;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    font-weight: 600;
    line-height: 28px;
    margin: 4px 0;
  }

  .modd-page-header { 
    margin: 16px 0;
    .modd-breadcrumb button {
      padding: 12px;
      margin-top: 2px;
      margin-right: 12px;
      .modd-icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .modd-page-subtitle {
    color: rgba(109, 113, 117, 1);
  }
}
.visually-hidden { display: none !important; }

.modd-navigation {
  width: calc(100vw - 64px);
  max-width: 240px;
  height: 100%;
  min-height: 100vh;
  padding: 8px;
  box-sizing: border-box;
  
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-right: 1px solid rgba(225, 227, 229, 1);

  .modd-navigation-section {
    .modd-link, .modd-link:visited { color: rgb(32, 34, 35); }
    .modd-navigation-item {
      display: block;
      padding: 6px;
      padding-left: 24px;
      &:hover, &.modd-active { background-color: rgba(241, 242, 243, 1);  }
      &.modd-active .modd-link { color: #086; }
      &.modd-active svg { fill: #086; }
    }
    .modd-navigation-section {
      .modd-navigation-item {
        padding-left: 48px;
        color: rgba(109, 113, 117, 1); font-weight: 500;
        &.modd-active { color: #086; }
        &.modd-active svg { fill: #086; }
      }
    }
  }
}

.modd-icon {
  width: 20px;
  height: 20px;
}
.modd-icon.modd-size-small {
  width: 16px;
  height: 16px;
}
.modd-icon.modd-size-large {
  width: 40px;
  height: 40px;
}
.modd-icon.modd-color-primary svg { fill: #086;  }
.modd-icon.modd-color-critical svg { fill: #c00;  }

.modd-modal-overlay:not(.modd-open) { z-index: -1; }
.modd-modal:not(.modd-open-initial):not(.modd-open-full) { display: none !important; }
.modd-modal:not(.modd-open-full) { transform: translate(0, 100vh); }


.modd-modal-overlay, .modd-modal-background {
  z-index: 100;
  width: 100%;
  transition: 0.25s;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .modd-modal-background {
    background: #000;
    opacity: 0.0;
  }
  &.modd-open .modd-modal-background {
    opacity: 0.5;
  }
}

.modd-modal {
  position: relative;
  transition: 0.25s;
  top: 0;
  z-index: 101;
  width: 100%;
  max-width: 640px;

  &.modd-large {

    max-width: 1024px;
  }

  .modd-card-header {
    border-bottom: 1px solid #eee;
    padding: 20px;
  }

  &.modd-card .modd-card-header-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
  }

  &.modd-card .modd-card-section {
    max-height: 30vh;
    overflow-y: auto;
  }
  &.modd-large.modd-card .modd-card-section {
    max-height: 50vh;
  }
}

.modd-toast {
  position: fixed;
  bottom: 0;
  font-size: 24px;
  text-align: center;
  color: #fff;
  z-index: 1000;
  padding: 32px;
  width: 100%;
  background: #555;
  &.modd-error {
    background: #700;
  }
  opacity: 0;
  transition: 0.25s;
  &.modd-open-full {
    opacity: 1;
  }
}

.modd-frame {
  background: rgba(246, 246, 247, 1);
}

.modd-tag {
  background: #ddd;
  padding-left: 8px;
  &:not(.modd-remove) {
    padding: 4px 8px;
  }
  border-radius: 4px;
  .modd-button {
    border-radius: 0;
  }
}

.modd-popover { position: relative; }
.modd-popover:not(.modd-open-initial):not(.modd-open-full) .modd-popover-children { 
  display: none !important; 
}

.modd-popover.modd-open-full .modd-popover-children > * {
  opacity: 1.0;
}
.modd-popover-children > * {
  transition: 0.25s;
  position: absolute;
  z-index: 80;
  opacity: 0.0;
  max-height: 600px;
  overflow-y: auto;
  
  min-width: 100%;
  width: max-content;
  margin-top: 6px;
  box-sizing: border-box;

  
  background: #fff;
  padding: 8px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 4px;
}

.modd-popover-children.modd-align-left > * {
  left: 0;
}
.modd-popover-children.modd-align-right > * {
  right: 0;
}

.modd-select + .modd-popover-children > * {
  background: #eee;
  .modd-button.modd-plain:hover:not(:disabled) {
    background: #ddf
  }
}

.modd-action-list {
  .modd-button { text-align: left; }
}

@keyframes spinner_svv2 { 100% { transform:rotate(360deg) } }

.modd-spinner { transform-origin:center; animation:spinner_svv2 .75s infinite linear; stroke-width: 2px; margin: 0 auto; svg { fill: #086; } }
.modd-spinner.modd-size-tiny { width: 12px; height: 12px; }
.modd-spinner.modd-size-small { width: 24px; height: 24px; }
.modd-spinner.modd-size-medium { width: 36px; height: 36px; }
.modd-spinner.modd-size-large { width: 48px; height: 48px; }
.modd-spinner.modd-size-extraLarge { width: 64px; height: 64px; }
.modd-button.modd-loading > *:not(.modd-spinner) { visibility: hidden; }
.modd-button.modd-loading .modd-spinner { position: absolute; top: 6px; left: 6px; right: 6px; bottom: 6px; }

.modd-empty-state {
  padding: 48px; 
  text-align: center;
  
  .modd-empty-state-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
  }
}

.modd-text-field, .modd-text-field-wrapped, .modd-select, .modd-action-list, .modd-datepicker {
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  border: none;
  box-sizing: border-box;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  width: 100%;
  min-width: 0;
  margin: 0;
  background: none;
  font-family: inherit;
  caret-color: rgb(32, 34, 35);
  color: rgb(32, 34, 35);
  resize: none;
  -webkit-appearance: none;
  appearance: none; 
}
.modd-select, .modd-datepicker, .modd-text-field, .modd-action-list {
  padding: 5px 12px;
  &:focus-visible {
    outline: 1px solid #48f
  }
}
.modd-text-field-wrapped, .modd-text-field-prefix {
  &:focus-visible {
    outline: 0;
  }
  padding: 5px 12px;
}

.modd-text-field-prefix ~ .modd-text-field-wrapped {
  padding-left: 0;
}

.modd-action-list-title{
  color: rgba(97, 97, 97, 1);
  font-weight: 650;
}

.modd-text-field-wrapper, .modd-text-field, .modd-select, .modd-datepicker, .modd-action-list {
  border: 1px solid rgba(201, 204, 207, 1);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  &.modd-error {
    border: 1px solid rgba(255, 107, 107, 1);
    &:focus-visible {
      outline: 1px solid rgb(200, 0, 0);
    }
  }
  &:disabled {
    background: #eee;
    cursor: not-allowed;
  }
}
.modd-text-field-wrapper {
  display: flex;
  align-items: center;
  &:has(input:focus-visible) {
    outline: 1px solid #48f
  }
}

.modd-error-text, .modd-inline-error {
  color: rgba(255, 107, 107, 1);
}
.modd-select, .modd-action-list { 
  cursor: pointer; 
  background-position: right 8px top 50%;
  background-size: 18px;
  background-repeat: no-repeat;
  padding-right: 32px;
  &.modd-button:hover {
    background-color: transparent !important;
  }
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' focusable='false' aria-hidden='true'%3E%3Cpath d='M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z'%3E%3C/path%3E%3C/svg%3E");
  option {
    background: #eee;
  }
}
.modd-select.modd-button {
  text-align: left;
}
.modd-checkbox, .modd-radio {
  appearance: none;
  padding: 6px;
  border: 1px solid #777;
  margin: 0 4px;
  cursor: pointer;
  position: relative;
  top: 2px;
  &:checked {
    background: #48f;
    border: 1px solid #48f;
  }
  &:disabled {
    background: #ddd;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
}
.modd-checkbox {
  padding: 8px;
  border-radius: 3px;
  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' %3E%3Cpath fill='%23fff' d='M14.723 6.237a.94.94 0 0 1 .053 1.277l-5.366 6.193a.834.834 0 0 1-.611.293.83.83 0 0 1-.622-.264l-2.927-3.097a.94.94 0 0 1 0-1.278.82.82 0 0 1 1.207 0l2.297 2.43 4.763-5.498a.821.821 0 0 1 1.206-.056Z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 22px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}
.modd-radio {
  border-radius: 24px;
  &:checked {
    outline: 2px solid #48f;
    outline-offset: 2px;
    padding: 4px;
    margin-left: 6px;
    &:disabled {
      outline: 2px solid #ddd;
    }
  }
}

.modd-label { 
  cursor: pointer;
  display: block;
}

.modd-range {
  width: 100%;
  z-index: 1; /* if we don't have this, the thumbs poke out over popovers */
  transition: 0.25s;
  position: relative;
  appearance: none;
  cursor: pointer;
  &::-webkit-slider-thumb {
    z-index: 90;
    position: relative;
  }
  &::-webkit-slider-thumb:hover {
    transform: scale(1.2);
    outline: 1px solid #48f;
    border-radius: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    height: 4px;
    width: calc(var(--progress) + 4px);
    top: 6px;
    z-index: 50;
    background: #48f;
    background-size: 4px 4px;
    border-radius: 4px;
    border-right: 4px #aaa solid;
  }
  &:after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    top: 6px;
    z-index: 25;
    background-image: linear-gradient(to right, #aaa, #aaa 50%, transparent 50%, transparent 100%);
    background-size: 4px 4px;
    border-radius: var(--p-border-radius-base);
    border-right: var(--p-border-radius-base) #aaa solid;
  }
}

.modd-help-text {
  color: #777;
}
.modd-tabs {
  .modd-tabs-button {
    border-radius: 0;
    padding: 12px 12px;
    margin: 0 12px;
    color: #777;
    &:hover {
      background: none !important;
      color: #000;
    }
  }
  .modd-tabs-button-selected {
    border-bottom: 2px solid #086;
    color: #000;
  }
}

.modd-button-group {
  .modd-stack-item button, & > .modd-stack-item > * {
    height: 100%;
  }
  &.modd-segmented {
    .modd-button {
      border-radius: 0;
    }
    & > *:first-child:not(:only-child) .modd-button {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    & > *:last-child:not(:only-child) .modd-button {
      border-radius: 0 4px 4px 0;
    }
    & > *:only-child .modd-button {
      border-radius: 4px;
    }
  }
}
.modd-badge {
  display: inline-flex;
  gap: 2px;
  border-radius: 12px;
  padding: 3px 6px;
  color: #000;
  background: #eee;
  &.modd-progress-complete:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 12px;
    top: 4px;
    position: relative;
  }
  &.modd-status-success:before { background-color: rgba(0, 127, 95, 1); }
  &.modd-status-success { background-color: rgba(174, 233, 209, 1); }
  &.modd-status-attention:before { background-color: rgba(185, 137, 0, 1); }
  &.modd-status-attention { background-color: rgba(255, 215, 157, 1); }
}

.modd-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .modd-layout-section {
    flex: 2 1 300px;
    min-width: 51%;
  }
  .modd-layout-section-secondary {
    flex: 1 1 150px;
    min-width: 0;
  }
}

.modd-tooltip {
  position: relative;
  cursor: help;
  .modd-tooltip-content {
    position: absolute;
    right: 0;
    left: 0;
    opacity: 0;
    visbility: hidden;
    z-index: -1;
    font-size: 14px;
    font-weight: normal;
    transition: 0.25s;
    margin-top: 4px;
    width: 400px;
    background: #fff;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 4px;
  }
  &:hover .modd-tooltip-content {
    opacity: 1;
    visbility: visible;
    z-index: 90;
  }
}

.modd-drop-zone {
  input[type='file'] { display: none; }
  min-height: 160px;
  border: 2px dashed #999;
  cursor: pointer;

  &.modd-hover {
    .modd-drop-zone-file-upload { background: #eee; }
  }

  .modd-drop-zone-file-upload { 
    padding: 20px; 
    &:hover { background: #eee; }
  }
}

.modd-contextual-save-bar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 6px;
  background: #333;
  color: #fff;
  z-index: 70;
}

.modd-option-list > * {
  padding: 6px 12px;
  border-radius: 4px;
  &:hover:not(:has(input:disabled)) {
    background: #f8f8ff;
  }
  &:has(input:checked) {
    background: #eef;
  }
  &:has(input:checked):has(input:disabled) {
    background: #eee;
  }
}

@media screen and (max-width: 520px) {
  .modd-display-desktop { display: none; }
  .modd-page {
    padding: 0;
    margin: 0;
  }
  .modd-breadcrumbs {   
    display: none;
  }
  .modd-top-navigation 
  .modd-top-navigation-mobile {
    display: block;
  }
}

.modd-text-container, .modd-card {
  p { margin: 0; }
  & > *:not(:last-child):not(.modd-card-section) {
    margin-bottom: 8px;
  }
}
